import { HTTP_METHODS } from "src/constants";
import { makeCall } from "src/helpers";
import { isRLTLang } from "src/i18n";
import { store } from "src/configureStore";
import { isEmpty } from "lodash";
import CryptoJS from 'crypto-js';
const FETCH_ENDPOINT = "/api/country_list";
const LOCAL_STORAGE_KEY = "foreignNationalitiesList";
const LOCAL_STORAGE_KEY_AR = "foreignNationalitiesListRTL";

const formatResponse = (items, param) =>
  items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')

const formatResponseForeignNationality = (items, param) =>{
  items = items.filter(item => item.labelCountryFr.toLowerCase() !== "tunisie");  
  return items.map((item) => ({
    label: isRLTLang() ? item.labelNationalityAr : item.labelNationalityFr,
    value: item.idCountry,
    ...item,
  })).filter(item => item.idCountry !== 'CE')}

export const NationalitiesUIHelper = (callback) => {
  const { token } = store.getState().common.auth || {}
  console.log("NationalitiesUIHelper",token)

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatResponse(resp.data))))
      .catch(() => reject(callback([])))
  );

};

export const ForeignNationalitiesUIHelper = async (callback) => {
  const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cachedData) {
    // If data is available in localStorage, return it
    callback(JSON.parse(cachedData));
    return Promise.resolve();
  }
  
  let secretKey = process.env.REACT_APP_RECAPCHA_LIST_SITE_KEY; // Use a secure key
  if (secretKey===undefined){
    secretKey = '6LcdY3EqAAAAAC9Jaf7kNckP4OR3rLFF';

  }
  console.log('secretkey',secretKey)
  let captchaToken = null;
  try {
    // Call the API to get the captcha token
    const response = await makeCall(HTTP_METHODS.GET, '/api/captcha-token');
    captchaToken = response.data?.captchaToken;

    if (!captchaToken) {
      console.error('Failed to fetch captcha token');
      return Promise.reject('Captcha token is missing');
    }
  } catch (error) {
    console.error('Error fetching captcha token', error);
    return Promise.reject(error);
  }
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(captchaToken), 
    CryptoJS.enc.Utf8.parse(secretKey), 
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );
  console.log("IV (Base64):", iv.toString(CryptoJS.enc.Base64));
console.log("Encrypted (Base64):", encrypted.toString());
  // Combine the IV and the encrypted data into a single string
  const encryptedToken = iv.toString(CryptoJS.enc.Base64) + ":" + encrypted.toString();
  // Encrypt the captcha token
  // const encryptedToken = CryptoJS.AES.encrypt(JSON.stringify(captchaToken), secretKey).toString();
  console.log("ForeignNationalitiesUIHelper Encrypted Captcha Token", encryptedToken);
  let headers={}
  headers["Captcha-Token"] = encryptedToken;

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, headers)
      .then((resp) => {
        const formattedData = formatResponseForeignNationality(resp.data);
        // Save formatted data to localStorage based on isRLTLang()
        if (isRLTLang()) {
          localStorage.setItem(LOCAL_STORAGE_KEY_AR, JSON.stringify(formattedData));
        } else {
          localStorage.setItem(LOCAL_STORAGE_KEY_AR, JSON.stringify(formattedData));
        }
        resolve(callback(formattedData));
      })
      .catch((error) => {
        console.error("Error in ForeignNationalitiesUIHelper", error);
        reject(callback([]));
      })
  );
};

const formatValueOptions = (options) => {
  let values = {}
  options.forEach((option) => {
    values[option.idCountry] = isRLTLang() ? option.labelNationalityAr : option.labelNationalityFr
  })
  return values;
}
export const NationalitiesOptionsUIHelper = (callback) => {
  const { token } = store.getState().common.auth || null
  console.log("NationalitiesOptionsUIHelper",token)

  return new Promise((resolve, reject) =>
    makeCall(HTTP_METHODS.GET, FETCH_ENDPOINT, { _pagination: false }, { Authorization: `Bearer ${token}` })
      .then((resp) => resolve(callback(formatValueOptions(resp.data))))
      .catch(() => reject(callback([])))
  );

};


